<!-- 弹框 -->
<template>
  <div class="sum-dialog-container">
    <el-dialog
      v-dialogDrag
      :title="dialogTit"
      :visible.sync="commonSumVisible"
      width="50%"
      :before-close="handleClose"
      append-to-body
    >
      <div>
        <!-- 条件 -->
        <div v-if="paramsTit.length" class="common-screen-container">
            <div class="common-input-container" v-for="(item, i) in paramsTit" :key="i">
                <span>{{ item.name }}:</span>
                <el-input v-if="item.type == 'input'" v-model="headerParams[item.modelKey]" placeholder="请输入内容" class="common-screen-input" clearable ></el-input>
            </div>
            <el-button v-show="paramsTit.length" class="common-screen-btn" type="primary" @click=" () => {getHeaderList(headerType)}" >查 询</el-button>
            <el-button v-show="paramsTit.length" class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
        </div>
        <!-- 列表 -->
        <el-table  v-loading="loading" element-loading-text="数据较多，拼命加载中..." :data="headerTableData">
          <el-table-column v-for="(item, i) in childColName" :key="i" :label="item.name" :formatter="tableColumn" :show-overflow-tooltip="true">
            <template slot="header" v-if="item.tableHead">
              <div>
                <span>{{item.name}}</span>
              </div>
            </template>
            <template slot-scope="scope">
                <span v-if="item.colProp && item.colProp.length == 2">
                    <span>{{ DateTransform(scope.row[item.colProp[0]]) }}</span>
                    <br />
                    <span>{{ DateTransform(scope.row[item.colProp[1]]) }}</span>
                </span>
                <span class="table-btn" @click="() => { routerFun(scope.row, item.url, item.paramsArr) }" v-else-if="item.url">{{ scope.row[item.colProp[0]] || "--" }}</span>
                <span v-else>{{ scope.row[item.colProp[0]] && scope.row[item.colProp[0]] != 'null' ? scope.row[item.colProp[0]] : "--" }}</span>
            </template>
          </el-table-column>
        </el-table>
        <br/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Config, tableColumn, DateTransform } from "../../../utils/index.js";
export default {
  name: "",
  components: {},
  props: [
    "methodFuc",
    "commonSumVisible",
		"startDate",
		"endDate"
  ],
  data() {
    return {
      Config,
      tableColumn,
      DateTransform,
      headerParams: {
        nickName: '',
        startEnd: []
      },
      loading: false,
      headerTableData: [],
      dialogTit: '汇总',
      childColName: [],
      paramsTit: [],
      headerType: '',
      adminId: '',
      option: [], // 出账类型
      option2: [], //收款方式
      date: [], //日期
      proType: "", //项目类型
      taskTypeSelect: [],
      comId:"", //公司id （有些情况公司id判断传不过来）
      workType: "" ,//工单类型（任务||审批）
      creatFlag: ""
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    routerFun (row, url, paramsArr) { // 弹窗跳转
      let newArr = []
      paramsArr.map((item) => {
        newArr.push(`${item['urlKey']}=${row[item['paramsKey']]}`)
      })
      let routeUrl = url + '?' + newArr.join('&')
      window.open(routeUrl)
    },
    async getAllData(type, name, screen, arr, companyId,date,notClock, sourceType) { 
      // 父组件传值. type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据, companyId :默认选中的公司
      // date:日期    proType：首页项目类型   
        this.headerType = type;
        this.dialogTit = name;
        this.paramsTit = screen;
        this.childColName = arr;
        this.date = date //日期
        if (companyId || companyId == 0) {
            this.headerParams.companyId = companyId
        }
        if(date&&date.length){
            this.headerParams.startDate = DateTransform(date[0])
            this.headerParams.endDate = DateTransform(date[1])
        }
        if (notClock) {
            this.headerParams.notClock = notClock
        }
        if (sourceType) {
            this.headerParams.sourceType = sourceType
        }
        this.getHeaderList(type)
    },
    handleClose() {
      this.$emit("handleClose");
    },
    async getHeaderList() {
        this.loading = true;
        let params = { ...this.headerParams };
        if (this.startDate) {
            params.startDate = this.startDate
        }
        if (this.endDate) {
            params.endDate = this.endDate
        }
        const { data } = await this.methodFuc(params);
        this.headerTableData = data.list || []
        this.loading = false;
    },
    async resetBtn() {
        this.headerParams.nickName = ''
        this.getHeaderList(this.headerType);
    },
  }
};
</script>

<style lang="less" scoped>
.table-btn {
  color: #1890ff;
  cursor: pointer;
}
.orange {
  color: #f09009;
}
.sum-dialog-container {
  padding-top: 1px;
}
.label1{
  
  box-sizing: border-box;
  display: inline-block;
  color: #706EFF;
  border: 1px solid #706EFF;
  border-radius: 4px;
  background-color: #f4f4ff;
  font-size: 12px;
  padding: 2px 8px;
}
.label2{
  // height: 33px;
  display: inline-block;
  color: #CE358F;
  border: 1px solid #CE358F;
  border-radius: 4px;
  background-color: #fbeff6;
  font-size: 12px;
  padding: 2px 8px;
}
.label3{
  // height: 33px;
  display: inline-block;
  color: #F09009;
  border: 1px solid #F09009;
  border-radius: 4px;
  background-color: #fef6ec;
  font-size: 12px;
  padding: 2px 8px;
}
.pay{
  display: inline-block;
  width: 110px !important;
}
// .td{
//   &
// }
.el-table_3_column_46  {
  width: 110px !important;
}
</style>
